<template>
  <div class="boardWrite">
    <div class="boardrdw">
      <input type="text" :placeholder="$t('front.board.emptyTitle')" v-model="title"/>
    </div>
    <div class="boardrdw">
      <a class="gnsbtn betlistbtn">{{ $t('front.board.betListAdd') }}</a>
    </div>
    <div class="boardrdw">
      <textarea :placeholder="$t('front.board.commentPlaceholder')" v-model="content"></textarea>
    </div>
  </div>

  <div class="boardbtn pt60">
    <ui-button :className="'blsbtn'" :text="$t('front.board.writes')" @click="onClickSubmit"/>
    <ui-button :className="'blksbtn'" :text="$t('front.board.cancel')" @click="onClickCancel"/>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton'

export default {
  name: 'BoardWrite',
  components: {
    UiButton
  },
  created () {
    this.boardIdx = this.$route.params.boardIdx
    if (this.boardIdx) {
      this.loadBoardDetail(this.boardIdx)
    }
  },
  data () {
    return {
      boardIdx: null,
      title: null,
      content: null
    }
  },
  methods: {
    async loadBoardDetail (boardIdx) {
      const params = {
        boardIdx: boardIdx,
        boardType: this.boardType
      }
      const model = await this.getBoardDetail(params)
      this.title = model.title
      this.content = model.content
    },
    onClickCancel () {
      this.$router.go(-1)
    },
    onClickSubmit () {
      if (!this.title) {
        this.onAlert('warningart', 'front.board.emptyTitle')
        return false
      }
      if (!this.content) {
        this.onAlert('warningart', 'front.board.emptyContent')
        return false
      }
      const params = {
        title: this.title,
        content: this.content,
        boardType: 'bbs',
        category: 'normal'
      }
      if (this.boardIdx) {
        params.boardIdx = this.boardIdx
      }
      this.setSaveBoard(params)
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
